import { ReceiptHeaderImage } from './Extras/receipt-header-image';

export class StaticVariable {

    // static porturl = 'http://localhost:8081/TicketBookingApi/';

    // static porturl = 'http://192.168.43.214:8081/TicketBookingApi/';

    static porturl = 'http://148.66.133.79:8080/EdonationApi/';
    static LS_TempleDetails = "templeDetails";

    //  static porturl = 'http://localhost:8082/TicketBookingApi/';

    static TempleName = 'DHARUMAI ADHEENAM E- PAY';
    static District = 'DHARUMAPURAM';
    static Pincode = '609001';
    static Email = '';
    static receiptBanner = ReceiptHeaderImage.receiptBanner;
    static status_1 = 'INITIAL';
    static status_2 = 'SUCCESS';
    static status_3 = 'FAILED';
    static status_4 = 'PENDING';
    static smsEncryptOffset = 25;
    static technicalSupport = '';
    static mail1 = '';
    static mail2 = '';
    static landLine = 'Mutt -04364 223 207';
    static tollFree = '';
    static phoneNo = 'GM - 9443107809'
    static pgKey1 = 'SxwgqL';
    static pgKey2 = '';
    static pgSalt1 = 'zC7VUYYY';
    static pgSalt2 = '';

}


