import { Component, OnInit } from '@angular/core';
import { StaticVariable } from 'src/app/globle.class';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
    templeName: string;
    districtName: string;
    pincode: string;
    ladnline: String;
    mail: string;
    phoneNo:string;
  constructor() { }

    ngOnInit() {
        this.templeName = StaticVariable.TempleName;
        this.districtName = StaticVariable.District
        this.pincode = StaticVariable.Pincode;
        this.ladnline = StaticVariable.landLine;
        this.mail = StaticVariable.Email;
        this.phoneNo = StaticVariable.phoneNo;
  }

}
