import { Component, OnInit } from '@angular/core';
import { DevoteeUserLoginService } from 'src/app/Services/devotee-user-login.service';
import { OtpMasterService } from 'src/app/Services/otp-master.service';
import { TransactionService } from 'src/app/Services/transaction.service';

import Swal from 'sweetalert2';
import { DevoteeUserMasterPojo } from 'src/app/Pojos/devotee-user-master.pojo';
import { OtpMasterPojo } from 'src/app/Pojos/otp-master.pojo';
import { TransactionTicketBookingPojo } from 'src/app/Pojos/transaction-ticket-booking.pojo';
import { DevoteeUserMasterService } from 'src/app/Services/devotee-user-master.service';
import { ActivatedRoute, Router } from '@angular/router';
declare var $: any;
declare var bootbox: any;

@Component({
    selector: 'app-user-login',
    templateUrl: './user-login.component.html',
    styleUrls: ['./user-login.component.scss']
})

export class UserLoginComponent implements OnInit {

    //Loading Flag
    isLoading = false;

    // devotee user master pojo
    devoteeUserMaster = new DevoteeUserMasterPojo();
    loggedUser = new DevoteeUserMasterPojo();

    // otp master pojo object
    otpMaster = new OtpMasterPojo();

    // timer
    downloadTimer: any;

    transBooking: TransactionTicketBookingPojo;

    // button loading
    chooseFlag: boolean;
    passwordFlag: boolean;
    passwordLoading: boolean;
    otpFlag: boolean;
    resendFlag: boolean;
    otpLoading: boolean;
    forgetPasswordFlag: boolean = false;
    checkNumberFlag: boolean = false;
    otpPage: boolean;
    checkOtpFlag: boolean;
    changePasswordFlag: boolean;
    changePasswordLoading: boolean;
    loginLoading: boolean;
    receiptDownloadFlag: boolean;
    checkTransactionFlag: boolean;
    captchaFlag: boolean;

    routerParam:string;


    constructor(private route: ActivatedRoute,  private devoteeUserLoginService: DevoteeUserLoginService, private otpMasterService: OtpMasterService, private router: Router, private transactionService: TransactionService, private devoteeUserMasterService: DevoteeUserMasterService) { }

    ngOnInit() {
         this.route.params.subscribe(parameters => { this.routerParam = parameters['data']; });
         if(this.routerParam == 'quickbooking'){
             this.quickClick();
         }

        this.passwordFlag = true;
        this.otpFlag = true;
        this.captchaFlag = false;
        this.quickClick();
        // this.router.navigate([`/user/userdashboard`]);
    }

    public resolved(captchaResponse: string) {
        if (captchaResponse != null) {
            console.log(`Resolved captcha with response: ${captchaResponse}`);
            this.captchaFlag = false;
        } else {
            console.log(`Resolved captcha with response: ${captchaResponse}`);
            this.captchaFlag = true;
        }
    }

    selectPassword() {
        this.passwordLoading = true;
        this.devoteeUserMaster.userName = this.devoteeUserMaster.mobileNo;
        this.devoteeUserLoginService.checkUsername(this.devoteeUserMaster.userName).subscribe(response => {
            this.passwordLoading = false;
            if (response.responseCode == 200) {
                Swal.fire({
                    title: 'Not Register!', text: 'Mobile number is not registered.', icon: 'error',
                    confirmButtonText: 'Do you want to Register?',
                    showCancelButton: true,
                    cancelButtonColor: '#ff4f81',
                    cancelButtonText: 'No',
                    onAfterClose: () => {
                        this.devoteeUserMaster.mobileNo = '';
                        $('#mobilenumber').focus();
                    }
                }).then((result) => {
                    if (result.value) {
                        this.router.navigate([`/userregistration`]);
                    }
                });
            } else if (response.responseCode == 101) {
                this.passwordFlag = false;
                this.chooseFlag = true;
            }
        }, error => {
            bootbox.alert({
                message: 'Check Devotee User Name Service Error! - HTTP Status ' + error.status, className: 'text-danger h5 text-center', closeButton: false
            });
            this.passwordLoading = false;
        });
    }

    countdown() {
        // var timeleft = 5;
        // this.downloadTimer = setInterval(() => {
        //     timeleft -= 1;
        //     document.getElementById("countdown").innerHTML = timeleft + 's';
        //     console.log(document.getElementById("countdown").innerHTML);
        //     if (timeleft == 0) {
        //         clearInterval(this.downloadTimer);
        //         console.log(document.getElementById("countdown").innerHTML);
        //         timeleft = null;
        //     }
        // }, 1000);
                this.resendFlag = true;
    }

    validateMobile() {
        const contactNum = /^[6-9]\d{9}$/;
        let arg: string;
        arg = this.devoteeUserMaster.mobileNo;
        if (arg != '' && arg != undefined) {
            if (arg.trim() != '') {
                if (arg.match(contactNum)) {
                } else {
                    Swal.fire({
                        title: 'Mobile Number!', text: 'Kindly Check The Mobile Number', icon: 'error',
                        onAfterClose: () => {
                            this.devoteeUserMaster.mobileNo = '';
                            $('#mobilenumber').focus();
                        }
                    })
                }
            } else {
                this.devoteeUserMaster.mobileNo = '';
            }
        }
    }

    selectOtp() {
        this.devoteeUserMaster.userName = this.devoteeUserMaster.mobileNo;
        this.countdown();
        if (this.devoteeUserMaster.mobileNo != '') {
            if (this.devoteeUserMaster.mobileNo != undefined) {
                this.otpLoading = true;
                this.otpMaster.description = 'LOGIN';
                this.devoteeUserLoginService.otpLogin(this.devoteeUserMaster.userName, this.otpMaster).subscribe(response => {
                    this.otpLoading = false;
                    if (response.responseCode == 200) {
                        this.countdown();
                        Swal.fire({
                            title: 'OTP', text: 'OTP Has Been Send To Your Registered Mobile Number', icon: 'success',
                            onAfterClose: () => {
                                $('#inputOtp').focus();
                            }
                        });
        this.otpFlag = false;
                        this.otpMaster.otpId = response.responseId;
        this.chooseFlag = true;
                    } else if (response.responseCode == 101) {
                        Swal.fire({
                            title: 'Not Register!', text: 'Mobile number is not registered.', icon: 'error',
                            confirmButtonText: 'Do you want to Register?',
                            showCancelButton: true,
                            cancelButtonColor: '#ff4f81',
                            cancelButtonText: 'No',
                            onAfterClose: () => {
                                this.devoteeUserMaster.mobileNo = '';
                                $('#mobilenumber').focus();
                            }
                        }).then((result) => {
                            if (result.value) {
                                this.router.navigate([`/userregistration`]);
                            }
                        });
                    } else if (response.responseCode == 102) {
                        Swal.fire({
                            title: 'OTP', text: 'Temporarly OTP Not Send', icon: 'error',
                            onAfterClose: () => {
                                $('#inputOtp').focus();
                            }
                        });
                    }
                }, error => {
                    bootbox.alert({
                        message: 'Devotee OTP Login Service Error! - HTTP Status ' + error.status, className: 'text-danger h5 text-center', closeButton: false
                    });
                    this.otpLoading = false;
                });
            } else {
                Swal.fire({
                    title: 'Mobile Number', text: 'Make sure mobile number is correct.', icon: 'error',
                    onAfterClose: () => {
                        $('#mobilenumber').focus();
                    }
                });
            }
        }
    }

    resendOtp() {
        this.countdown();
        this.otpMasterService.resendOtp(this.otpMaster.otpId).subscribe(response => {
            if (response.responseCode == 200) {
                this.countdown();
                Swal.fire({
                    title: 'OTP', text: 'New OTP Has Been Send To Your Registered Mobile Number', icon: 'success',
                    onAfterClose: () => {
                        $('#inputOtp').focus();
                    }
                });
            }
        }, error => {
            bootbox.alert({
                message: 'Resend Otp Service Error! - HTTP Status ' + error.status, className: 'text-danger h5 text-center', closeButton: false
            });
        });
    }

    forgetPassword() {
        this.forgetPasswordFlag = true;
    }

    checkUsername() {
        this.checkNumberFlag = true;
        this.devoteeUserMaster.userName = this.devoteeUserMaster.mobileNo;
        this.devoteeUserLoginService.checkUsername(this.devoteeUserMaster.userName).subscribe(response => {
            if (response.responseCode == 200) {
                Swal.fire({
                    title: 'Invalid Mobile Number!', text: 'Check Your Mobile Number.', icon: 'error',
                    onAfterClose: () => {
                        this.devoteeUserMaster.mobileNo = '';
                        $('#mobilenumber').focus();
                    }
                });
                this.checkNumberFlag = false;
            } else if (response.responseCode == 101) {
                let d = new OtpMasterPojo();
                d.mobileNo = this.devoteeUserMaster.mobileNo;
                d.description = 'FORGETPASSWORD';
                this.otpMasterService.generateOtp(d).subscribe(response => {
                    if (response.responseCode == 200) {
                        Swal.fire({
                            title: 'OTP', text: 'OTP Has Been Send To Your Registered Mobile Number', icon: 'success',
                            onAfterClose: () => {
                                $('#otp').focus();
                            }
                        });
                        this.otpPage = true;
                        this.otpMaster.otpId = response.responseId;
                        this.checkNumberFlag = false;
                    }
                }, error => {
                    bootbox.alert({
                        message: 'Generate Otp Service Error! - HTTP Status ' + error.status, className: 'text-danger h5 text-center', closeButton: false
                    });
                    this.checkNumberFlag = false;
                });
            }
        }, error => {
            bootbox.alert({
                message: 'Check Devotee User Name Service Error! - HTTP Status ' + error.status, className: 'text-danger h5 text-center', closeButton: false
            });
            this.checkNumberFlag = false;
        });
    }

    verifyOtp() {
        this.checkOtpFlag = true;
        this.otpMasterService.verifyOtp(this.otpMaster).subscribe(response => {
            if (response.responseCode == 200) {
                this.otpPage = false;
                this.changePasswordFlag = true;
            } else if (response.responseCode == 101) {
                Swal.fire({
                    title: 'Invalid OTP', text: 'Please Check Your OTP', icon: 'error',
                    onAfterClose: () => {
                        $('#otp').focus();
                    }
                });
            }
            this.checkOtpFlag = false;
        }, error => {
            bootbox.alert({
                message: 'Add Devotee User Master Service Error! - HTTP Status ' + error.status, className: 'text-danger h5 text-center', closeButton: false
            });
            this.checkOtpFlag = false;
        });
    }

    cancelOtp() {
        this.otpPage = false;
        clearInterval(this.downloadTimer);
        this.devoteeUserMaster = new DevoteeUserMasterPojo();
        this.back();
    }

    checklength() {
        if (this.devoteeUserMaster.password.length < 8) {
            if (this.devoteeUserMaster.password != '') {
                Swal.fire({
                    title: 'Password!', text: 'Minimum 8 Characters', icon: 'error',
                    onAfterClose: () => {
                        $('#newPassword').focus();
                        this.devoteeUserMaster.password = '';
                    }
                });
            }
        }
    }

    ValidatePassword(arg: string) {
        if (arg.trim() != '') {
            if (this.devoteeUserMaster.password != arg) {
                Swal.fire({
                    title: 'Confirm Password!', text: 'Doesn\'t Match With Password!', icon: 'error',
                    onAfterClose: () => {
                        (<HTMLInputElement>document.getElementById('confirmPassword')).value = '';
                        $("#confirmPassword").focus();
                    }
                });
            }
        }
    }

    changePassword() {
        this.changePasswordLoading = true;
        this.devoteeUserLoginService.passwordUpdate(this.devoteeUserMaster).subscribe(response => {
            this.changePasswordLoading = false;
            if (response.responseCode == 200) {
                Swal.fire({
                    title: 'Password', text: 'Successfully Updated', icon: 'success',
                    onAfterClose: () => {
                        this.back();
                        this.devoteeUserMaster = new DevoteeUserMasterPojo();
                    }
                });
            } else if (response.responseCode == 101) {
                Swal.fire({
                    title: 'Oops!', text: 'We can\'t update your password right now!', icon: 'warning',
                    onAfterClose: () => {
                        this.back();
                        this.devoteeUserMaster = new DevoteeUserMasterPojo();
                    }
                });
            }
        }, error => {
            bootbox.alert({
                message: 'Devotee Password Update Service Error! - HTTP Status ' + error.status, className: 'text-danger h5 text-center', closeButton: false
            });
            this.changePasswordLoading = false;
        });
    }

    back() {
        this.forgetPasswordFlag = false;
        this.passwordFlag = true;
        this.otpFlag = true;
        this.chooseFlag = false;
        this.receiptDownloadFlag = false;
    }

    loginClick() {
        this.loginLoading = true;
        this.devoteeUserMaster.userName = this.devoteeUserMaster.mobileNo;
        if (this.passwordFlag == false) {
            this.devoteeUserLoginService.userLogin(this.devoteeUserMaster).subscribe(response => {
                this.loginLoading = false;
                this.loggedUser = response;
                if (this.loggedUser.userName == this.devoteeUserMaster.userName && this.loggedUser.password == this.devoteeUserMaster.password) {
                    sessionStorage.setItem('CurrentSession', 'true');
                    sessionStorage.setItem('ETicketUserDetail', JSON.stringify(this.loggedUser));
                    this.router.navigate([`/user/userdashboard`]);
                } else {
                    Swal.fire({
                        title: 'Invalid Credential', text: 'Kindly check your username and password', icon: 'error',
                    });
                }
            }, error => {
                bootbox.alert({
                    message: 'Devotee Login Service Error! - HTTP Status ' + error.status, className: 'text-danger h5 text-center', closeButton: false
                });
                this.loginLoading = false;
            });
        } else if (this.otpFlag == false) {
            this.devoteeUserLoginService.userLoginViaOtp(this.devoteeUserMaster.userName, this.otpMaster).subscribe(response => {
                this.loginLoading = false;
                this.loggedUser = response;
                if (this.loggedUser.userName == this.devoteeUserMaster.userName) {
                    sessionStorage.setItem('CurrentSession', 'true');
                    sessionStorage.setItem('ETicketUserDetail', JSON.stringify(this.loggedUser));
                    this.router.navigate([`/user/userdashboard`]);
                } else {
                    Swal.fire({
                        title: 'Invalid Credential', text: 'Kindly check your username and otp', icon: 'error',
                    });
                }
            }, error => {
                bootbox.alert({
                    message: 'Devotee OTP Login Verify Service Error! - HTTP Status ' + error.status, className: 'text-danger h5 text-center', closeButton: false
                });
                this.loginLoading = false;
            });
        }
    }

    receiptDownload() {
        this.router.navigate([`/receiptdownload`]);
    }

    quickClick() {
        this.isLoading = true;
        sessionStorage.setItem('CurrentSession', 'true');
        this.devoteeUserMasterService.devoteeUserMasterSearchById(1).subscribe(response => {
            this.isLoading = false;
            this.loggedUser = response;
            sessionStorage.setItem('ETicketUserDetail', JSON.stringify(this.loggedUser));
            this.router.navigate([`/user/userdashboard`]);
        }, error => {
            bootbox.alert({
                message: 'Devotee User Master Search By Id Service Error! - HTTP Status ' + error.status, className: 'text-danger h5 text-center', closeButton: false
            });
            this.isLoading = false;
            // this.router.navigate([`/user/booking`]);
        });
    }


}
